<template>
  <v-sheet>
    <v-container fluid>
      <v-row justify="space-around">
        <v-col cols="12">
          <v-container fluid>
            <v-row align="center">
              <v-col
                class="d-flex"
                cols="12"
                sm="8"
              >
                <h3>
                  Manage Banner
                  
                  <v-btn
                    fab
                    dark
                    small
                    color="indigo"
                    @click="insertBanner(0)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </h3>
              </v-col>

              <v-col
                class="d-flex"
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="currentCategoryId"
                  :items="categories"
                  label="Category"
                  item-text="name"
                  item-value="categoryId"
                  @change="loadBanners()"
                ></v-select>
              </v-col>
            </v-row>

            <div class="text-center">            
              <v-overlay :value="loading">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
              <draggable v-model="items" tag="div" @change="saveBanners">
                <v-card v-for="(item) in items" :key="item.bannerId">
                  <v-img
                    :src="item.imageUrl"
                    :alt="item.title"
                  />
                
                  <v-speed-dial
                    v-model="item.fab"
                    :bottom="true"
                    :right="true"
                    direction="top"
                  >
                    <template v-slot:activator>
                      <v-btn
                        v-model="item.fab"
                        color="blue darken-2"
                        dark
                        fab
                      >
                        <v-icon v-if="item.fab">
                          mdi-close
                        </v-icon>
                        <v-icon v-else>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-btn
                      fab
                      dark
                      small
                      color="green"
                      @click="editBanner(item.bannerId)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click="insertBanner(item.bannerId)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      dark
                      small
                      color="red"
                      @click="deleteBanner(item.bannerId)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </v-card>
              </draggable>
            </div>
          </v-container></v-col>
      </v-row>
    </v-container>
  
    <v-dialog
      v-model="dialogEdit"
      persistent
      max-width="600px"
    >
      <v-form ref="form" v-model="isValid">
        <v-card>
          <v-card-title>
            <span class="headline">Banner Details</span>
          </v-card-title>
          <v-card-text>
            <small>banner id: {{ editedBanner.bannerId }}</small>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="editedBanner.title"
                    label="Title"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="editedBanner.linkUrl"
                    label="Link"
                    hint="only if the banner should link to somewhere"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="bannerLinkTarget"
                    :items="['No', 'Yes']"
                    label="Open link on a new tab?"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-file-input
                    ref="imageUpload"
                    v-model="bannerFile"
                    :rules="[rules.required, rules.size]"
                    accept="image/png, image/jpeg"
                    prepend-icon="mdi-camera"
                    label="Banner Image"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="cancelInsert()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!isValid"
              @click="save(editedBanner.bannerId)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </v-sheet>
</template>

<script>

import axios from 'axios'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  data () {
    return {
      info: null,
      loading: true,
      errored: false,
      categories: [{}],
      currentCategoryId: 0,
      items: [{}],
      dragging: false,
      dialogEdit: false,
      editedBanner: {},
      bannerFile: null,
      rules: {
        size: (value) => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
        required: (value) => {
          if (this.editedBanner.bannerId === '') {
            return !!value || 'Image is required!'
          }

          return true
        }
      },
      isValid: true
    }
  },
  computed: {
    bannerLinkTarget: {
      get: function () {
        return this.editedBanner.target === '_blank' ? 'Yes' : 'No'
      },
      set: function(v) {
        v === 'Yes' ? this.editedBanner.target = '_blank' : this.editedBanner.target = null
      } 
    },
    activeFab () {
      switch (this.tabs) {
      case 'one': return { class: 'purple', icon: 'account_circle' }
      case 'two': return { class: 'red', icon: 'edit' }
      case 'three': return { class: 'green', icon: 'keyboard_arrow_up' }
      default: return {}
      }
    }
  },
  async mounted () {
    await this.loadBannersCategories()
  },
  methods: {
    async saveBanners() {
      let counter = 1

      this.items.forEach((item) => {
        item.orderId = counter
        counter++
      })

      axios({
        method: 'put',
        url: this.getBannersApiUrl(),
        headers: await this.getHeaders(),
        data: JSON.stringify(this.items)
      })
        .then((response) => {          
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    cancelInsert() {
      this.items = this.items.filter((b) => b.bannerId !== '')
      this.bannerFile = null
      this.dialogEdit = false
    },
    async deleteBanner(bannerId) {
      axios({
        method: 'delete',
        url: this.getBannersApiUrl() + '/' + bannerId,
        headers: await this.getHeaders()
      })
        .then((response) => {          
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
      
      this.items = this.items.filter((b) => b.bannerId !== bannerId)
    },
    editBanner(bannerId) {
      this.editedBanner = this.items.find((b) => b.bannerId === bannerId)
      this.dialogEdit = true
      if (this.$refs.form !== undefined) {
        this.$refs.form.validate()
      }
    },
    insertBanner(bannerId) {      
      const insertingBanner = this.items.find((b) => b.bannerId === bannerId)

      const newBanner = {
        bannerId: '',
        title: '',
        imageUrl: '',
        linkUrl: '',
        target: '',
        orderId: 0
      }
      
      const index = this.items.indexOf(insertingBanner)

      this.items.splice(index + 1, 0, newBanner)
      this.editedBanner = newBanner
      this.dialogEdit = true
      if (this.$refs.form !== undefined) {
        this.$refs.form.validate()
      }
    },
    async save(bannerId) {
      const banner = this.items.find((b) => b.bannerId === bannerId)

      banner.title = this.editedBanner.title
      banner.linkUrl = this.editedBanner.linkUrl
      banner.target = this.editedBanner.target

      const headers = await this.getHeaders()

      this.$set(headers, 'Content-Type', 'multipart/form-data')

      const formData = new FormData()

      if (this.bannerFile) {
        formData.append('file', this.bannerFile, this.bannerFile.name )
      }

      formData.append('BannerId', banner.bannerId)
      formData.append('LinkUrl', banner.linkUrl)
      formData.append('ImageUrl', banner.imageUrl)
      formData.append('OrderId', banner.orderId)
      formData.append('Target', banner.target)
      formData.append('Title', banner.title)

      axios({
        method: 'post',
        url: this.getBannersApiUrl(),
        headers: headers,
        data: formData
      })
        .then(async (response) => {          
          console.log(response)
          banner.bannerId = response.data.bannerId          
          banner.imageUrl = response.data.imageUrl
          this.bannerFile = null
          await this.saveBanners()
        })
        .catch((error) => {
          console.log(error)
        })

      this.dialogEdit = false
    },
    async getHeaders() {
      const accessToken = await this.$msal.acquireToken()

      return {
        Authorization: 'Bearer ' + accessToken,
        'Ocp-Apim-Subscription-Key': process.env.VUE_APP_BANNERS_API_KEY
      }
    },
    getBannersApiUrl() {
      return `${process.env.VUE_APP_BANNERS_API_URL}banners/${process.env.VUE_APP_BANNERS_API_ENTITY}/${this.currentCategoryId}`
    },
    getCategoriesApiUrl() {
      return `${process.env.VUE_APP_BANNERS_API_URL}categories/${process.env.VUE_APP_BANNERS_API_ENTITY}`
    },
    async loadBanners() {
      axios ({
        method: 'get',
        url: this.getBannersApiUrl(),
        headers: await this.getHeaders()
      })
        .then((response) => {
          this.items = response.data[0].banners.sort((a, b) => Number(a.orderId) - Number(b.orderId))
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.loading = false)
    },
    async loadBannersCategories() {
      axios ({
        method: 'get',
        url: this.getCategoriesApiUrl(),
        headers: await this.getHeaders()
      })
        .then((response) => {          
          if (response.data !== null && response.data.length > 0) {
            this.categories = response.data.sort((a, b) => String(a.name) - String(b.name))
            this.currentCategoryId = this.categories[0].categoryId
            this.loadBanners()
          }
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>