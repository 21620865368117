<template>
  <div>
    <ToolbarAdmin />
    <AdminBannersList />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ToolbarAdmin from '@/components/admin/ToolbarAdmin.vue'
import AdminBannersList from '@/components/admin/AdminBannersList.vue'

export default {
  components: {
    ToolbarAdmin,
    AdminBannersList
  },
  computed: {
    ...mapGetters('app', {
      isAuthenticated: 'isAuthenticated',
      canUploadBanners: 'canUploadBanners'
    })
  },
  mounted() {
    if (!this.isAuthenticated || !this.canUploadBanners) {
      this.$router.push('/')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Banners'
      }
    }
  }
}
</script>